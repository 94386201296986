import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import NonAuthenticatedRoute from './routes/NonAuthenticatedRoute';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import LoadingContainer from './shared/containers/Loading';

const AsyncHome = lazy(() => import('./routes/Home'));
// const AsyncServices = lazy(() => import('./routes/Home/Services'));
const AsyncPermitDesign = lazy(() => import('./routes/Home/PermitDesign'));
const AsyncEngineering = lazy(() => import('./routes/Home/Engineering'));
const AsyncStructuralEng = lazy(() => import('./routes/Home/StructuralEng'));
const AsyncElectricalEng = lazy(() => import('./routes/Home/ElectricalEng'));
// const AsyncPlatform = lazy(() => import('./routes/Home/Platform'));
const AsyncAbout = lazy(() => import('./routes/Home/AboutUs'));
const AsyncBlog = lazy(() => import('./routes/Home/Blog'));
const AsyncBlogPost = lazy(() => import('./routes/Home/Blog/BlogPost'));
// const AsyncCustomers = lazy(() => import('./routes/Home/Customers'));
const AsyncContact = lazy(() => import('./routes/Home/Contact'));
const AsyncPrivacy = lazy(() => import('./routes/Home/Privacy'));
const AsyncTerms = lazy(() => import('./routes/Home/Terms'));
const AsyncLogin = lazy(() => import('./routes/Actions/Login'));
const AsyncRequestDemo = lazy(() => import('./routes/Actions/RequestDemo'));
const AsyncForgotPassword = lazy(
  () => import('./routes/Actions/ForgotPassword')
);
const AsyncVerifyEmail = lazy(() => import('./routes/Actions/VerifyEmail'));
const AsyncDashboard = lazy(() => import('./routes/Dashboard'));

const Routes = () => {
  return (
    <Suspense fallback={<LoadingContainer />}>
      <Switch>
        <NonAuthenticatedRoute path="/login">
          <AsyncLogin />
        </NonAuthenticatedRoute>
        <NonAuthenticatedRoute path="/request-demo">
          <AsyncRequestDemo />
        </NonAuthenticatedRoute>
        <Route path="/password-reset/:uid/:token">
          <AsyncForgotPassword />
        </Route>
        <Route path="/password-reset">
          <AsyncForgotPassword />
        </Route>
        <Route path="/verify-email/:key">
          <AsyncVerifyEmail />
        </Route>
        {/* <Route path="/services">
          <AsyncServices />
        </Route> */}
        <Route path="/permit-design">
          <AsyncPermitDesign />
        </Route>
        <Route path="/engineering">
          <AsyncEngineering />
        </Route>
        <Route path="/structural-engineering">
          <AsyncStructuralEng />
        </Route>
        <Route path="/electrical-engineering">
          <AsyncElectricalEng />
        </Route>
        {/* <Route path="/platform">
          <AsyncPlatform />
        </Route> */}
        <Route path="/about">
          <AsyncAbout />
        </Route>
        <Route path="/blog/:postId">
          <AsyncBlogPost />
        </Route>
        <Route path="/blog">
          <AsyncBlog />
        </Route>
        {/* <Route path="/customers">
          <AsyncCustomers />
        </Route> */}
        <Route path="/contact-us">
          <AsyncContact />
        </Route>
        <Route path="/terms">
          <AsyncTerms />
        </Route>
        <Route path="/privacy-policy">
          <AsyncPrivacy />
        </Route>
        <Route path="/home/redirect/:shouldClose">
          <AsyncHome />
        </Route>
        <Route path="/home">
          <AsyncHome />
        </Route>
        <NonAuthenticatedRoute exact path="/">
          <AsyncHome />
        </NonAuthenticatedRoute>
        <AuthenticatedRoute>
          <AsyncDashboard />
        </AuthenticatedRoute>
      </Switch>
    </Suspense>
  );
};

export default Routes;
