import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const CompaniesContext = createContext(null);

const initialState = {
  loaded: false,
  companies: [],
  states: [],
  support_contacts: []
};

export const COMPANIES_TYPES = {
  LOADED: 'COMPANIES/LOADED',
  UPDATE_COMPANIES_DATA: 'COMPANIES/UPDATE_COMPANIES_DATA',
  UPDATE_COMPANIES: 'COMPANIES/UPDATE_COMPANIES',
  UPDATE_SUPPORT_CONTACTS: 'UPDATE_SUPPORT_CONTACTS'
};

const reducer = (state, action) => {
  switch (action.type) {
    case COMPANIES_TYPES.LOADED:
      return {
        ...state,
        loaded: true,
        companies: [...action.payload.companies],
        states: [...action.payload.states],
        support_contacts: [...action.payload.supportContacts]
      };
    case COMPANIES_TYPES.UPDATE_COMPANIES_DATA:
      return {
        ...state,
        companies: [...action.payload.companies]
      };
    case COMPANIES_TYPES.UPDATE_COMPANIES:
      return {
        ...state,
        companies: action.payload.newCompany
          ? [...action.payload.newCompany]
          : state.companies.map(company => {
              if (company.id === action.payload.updatedCompany.id) {
                return action.payload.updatedCompany;
              }
              return company;
            })
      };
    case COMPANIES_TYPES.UPDATE_SUPPORT_CONTACTS:
      return {
        ...state,
        support_contacts_loaded: true,
        support_contacts: action.payload.supportContacts
      };
    default:
      return state;
  }
};

const CompaniesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <CompaniesContext.Provider value={value}>
      {children}
    </CompaniesContext.Provider>
  );
};

CompaniesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { CompaniesContext, CompaniesProvider };
