import React, { createContext, useReducer, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { getCurrentUser } from '../../shared/Api';
import LoadingContainer from '../../shared/containers/Loading';

const AppContext = createContext(null);

const initialState = {
  initialized: false,
  isInitializing: false,
  isLoggingIn: false,
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  isAuthenticated: false,
  pageTitle: '',
  siteAlerts: [],
  user: {},
  monthStats: {
    jobsCompletedMonth: 0,
    revisionCountMonth: 0,
    previousShiftHours: 0.0,
    currentShiftStart: null,
    inDesignGroup: false
  }
};

export const ACTION_TYPES = {
  INITIALIZING: 'INITIALIZING',
  INIT_COMPLETE: 'INIT_COMPLETE',
  LOGGING_IN: 'LOGGING_IN',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
  UPDATE_SITE_ALERTS: 'UPDATE_SITE_ALERTS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_STATS: 'UPDATE_USER_STATS'
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.INITIALIZING:
      return {
        ...state,
        initialized: false,
        isInitializing: true
      };
    case ACTION_TYPES.INIT_COMPLETE:
      return {
        ...state,
        initialized: true,
        isInitializing: false
      };
    case ACTION_TYPES.LOGGING_IN:
      return {
        ...state,
        isLoggingIn: true
      };
    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isLoggingIn: false,
        accessToken: null,
        refreshToken: null,
        user: {}
      };
    case ACTION_TYPES.LOGGED_IN:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      };
    case ACTION_TYPES.LOGGED_OUT:
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        pageTitle: '',
        siteAlerts: [],
        user: {}
      };
    case ACTION_TYPES.SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload.pageTitle
      };
    case ACTION_TYPES.UPDATE_SITE_ALERTS:
      return {
        ...state,
        siteAlerts: action.payload
      };
    case ACTION_TYPES.UPDATE_USER:
      const userObject = action.payload.user || action.payload;
      return {
        ...state,
        user: {
          id: userObject.id || state.user.id,
          email: userObject.email,
          firstName: userObject.first_name,
          lastName: userObject.last_name,
          verifiedEmail: userObject.verified_email || state.user.verifiedEmail,
          phoneNumber: action.payload.user
            ? action.payload.phone_number
            : state.user.phoneNumber,
          role: action.payload.user ? action.payload.role : state.user.role,
          needsOnboarding: action.payload.user
            ? action.payload.needs_onboarding
            : state.user.needsOnboarding,
          needsPassword: action.payload.user
            ? action.payload.needs_password
            : state.user.needsPassword,
          jobNotificationsEnabled: action.payload.user
            ? action.payload.job_notifications_enabled
            : state.user.jobNotificationsEnabled,
          messageNotificationsEnabled: action.payload.user
            ? action.payload.message_notifications_enabled
            : state.user.messageNotificationsEnabled,
          companies: action.payload.user
            ? action.payload.companies
            : state.user.companies,
          rae_support_contact: action.payload.user
            ? action.payload.rae_support_contact
            : state.user.rae_support_contact
        },
        monthStats: {
          jobsCompletedMonth:
            action.payload.month_jobs_count ||
            state.monthStats.jobsCompletedMonth,
          revisionCountMonth:
            action.payload.month_revision_count ||
            state.monthStats.revisionCountMonth,
          previousShiftHours:
            action.payload.prev_shift_hours ||
            state.monthStats.previousShiftHours,
          currentShiftStart: action.payload.current_shift_start
            ? Date.parse(action.payload.current_shift_start)
            : state.monthStats.currentShiftStart,
          inDesignGroup:
            action.payload.is_design_group_member ||
            state.monthStats.inDesignGroup
        }
      };
    case ACTION_TYPES.UPDATE_USER_STATS:
      const newStats = state.monthStats;
      if (
        (action.payload.jobAction === 'approve' &&
          !state.monthStats.inDesignGroup) ||
        (action.payload.jobAction === 'setPendingQC' &&
          state.monthStats.inDesignGroup)
      ) {
        newStats.jobsCompletedMonth += 1;
      } else if (action.payload.jobAction === 'revise') {
        newStats.revisionCountMonth += 1;
      }
      return {
        ...state,
        monthStats: newStats
      };
    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  useEffect(() => {
    if (state.accessToken) {
      dispatch({
        type: ACTION_TYPES.INITIALIZING
      });
      getCurrentUser(dispatch)
        .then(() => {
          dispatch({
            type: ACTION_TYPES.LOGGED_IN,
            payload: {
              accessToken: state.accessToken,
              refreshToken: state.refreshToken
            }
          });
        })
        .catch(() => {
          dispatch({
            type: ACTION_TYPES.LOGIN_ERROR
          });
        })
        .finally(() => {
          dispatch({
            type: ACTION_TYPES.INIT_COMPLETE
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isInitializing) {
    return <LoadingContainer />;
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export { AppContext, AppProvider };
