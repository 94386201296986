import { alpha } from '@mui/material/styles';

import { createGradient } from '../helpers';

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#ffd374',
  light: '#ffc851',
  main: '#ffc240',
  dark: '#ffba27',
  darker: '#f5a700',
  contrastText: GREY[800]
};
const SECONDARY = {
  lighter: '#DEF0FF',
  light: '#83B4FF',
  main: '#83B4FF',
  dark: '#5A9BFF',
  darker: '#11297A',
  contrastText: GREY[800]
};
const INFO = {
  lighter: '#D5FEFF',
  light: '#64D3FF',
  main: '#64D3FF',
  dark: '#30C4FF',
  darker: '#093B7A',
  contrastText: GREY[800]
};
const SUCCESS = {
  lighter: '#CDFDD5',
  light: '#43F4A7',
  main: '#43F4A7',
  dark: '#0DE889',
  darker: '#026F6B',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFFBD9',
  light: '#FFEA73',
  main: '#FFEA73',
  dark: '#FFE344',
  darker: '#7A630D',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFEEDC',
  light: '#FF9D7F',
  main: '#FF9D7F',
  dark: '#FF7C54',
  darker: '#7A1015',
  contrastText: GREY[800]
};

// Misc colors
const DARK_BLUE = {
  light: '#4b78c8',
  main: '#4b78c8',
  dark: '#3e6ebc',
  contrastText: GREY[0]
};
const PURPLE = {
  light: '#e2bee8',
  main: '#CE93D8',
  dark: '#AB47BC',
  contrastText: GREY[800]
};
const LIGHT_PINK = {
  light: '#feb1bd',
  main: '#fc91ac',
  dark: '#fa5a83',
  contrastText: GREY[800]
};
const PINK = {
  light: '#f06d8a',
  main: '#fa5a83',
  dark: '#d43764',
  contrastText: GREY[800]
};
const DARK_PINK = {
  light: '#d5486d',
  main: '#bf325c',
  dark: '#a8174b',
  contrastText: GREY[200]
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const darkPalette = {
  mode: 'dark',
  common: {
    black: GREY[900],
    white: '#fff'
  },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  darkBlue: { ...DARK_BLUE },
  purple: { ...PURPLE },
  pink: { ...PINK },
  lightPink: { ...LIGHT_PINK },
  darkPink: { ...DARK_PINK },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  text: {
    primary: '#fff',
    secondary: GREY[500],
    disabled: GREY[600]
  },
  background: {
    paper: GREY[800],
    default: GREY[900],
    neutral: GREY[800]
  },
  action: {
    active: GREY[500],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_32],
    disabledBackground: GREY[500_12],
    focus: GREY[500_12],
    hoverOpacity: 0.08,
    disabledOpacity: 0.38
  }
};

export default darkPalette;
