import React from 'react';
import PropTypes from 'prop-types';

import { JobsProvider } from './routes/Dashboard/Jobs/Context';
import { CodesProvider } from './routes/Dashboard/Codes/Context';
import { UsersProvider } from './routes/Dashboard/Users/Context';
import { CompaniesProvider } from './routes/Dashboard/Companies/Context';
import { AnalyticsProvider } from './routes/Dashboard/Analytics/Context';
import { MessagingProvider } from './routes/Dashboard/Messaging/Context';
import { NotificationsProvider } from './routes/Dashboard/Notifications/Context';

function AppProviders({ children }) {
  return (
    <JobsProvider>
      <UsersProvider>
        <MessagingProvider>
          <CompaniesProvider>
            <NotificationsProvider>
              <AnalyticsProvider>
                <CodesProvider>{children}</CodesProvider>
              </AnalyticsProvider>
            </NotificationsProvider>
          </CompaniesProvider>
        </MessagingProvider>
      </UsersProvider>
    </JobsProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProviders;
