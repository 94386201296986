import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getTokens } from '../shared/utils/helpers';

const AuthenticatedRoute = ({ children, ...rest }) => {
  return (
    <Route {...rest}>
      {getTokens().accessToken ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              fromAuthRoute: true,
              from: rest.location.pathname
            }
          }}
        />
      )}
    </Route>
  );
};

AuthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthenticatedRoute;
