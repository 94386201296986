import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga4';
import LinkedInTag from 'react-linkedin-insight';
import { Wrapper as GoogleApiWrapper } from '@googlemaps/react-wrapper';

import { AppProvider } from './context/AppContext';
import ThemeConfig from './shared/utils/theme';
import App from './App';

Sentry.init({
  dsn: 'https://877c99116fdd48929ddefe37525fa21a@o1039139.ingest.sentry.io/6007908',
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  release: `right-angle-engineering@${process.env.VERSION}`
});

// Google Analytics
ReactGA.initialize([
  {
    trackingId: 'G-Z23GZFLYT0'
  },
  {
    trackingId: 'AW-10904940310'
  },
  {
    trackingId: 'GTM-PR25QXS7'
  }
]);

// LinkedIn Insight Tag
// TODO: In the future, we should really have a popup that's shown to users that allows them to either accept or deny third-party cookies, like most sites do
LinkedInTag.init('5618156', 'dc', false);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeConfig>
    <AppProvider>
      <SnackbarProvider
        maxSnack={10}
        dense
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={5000}
        preventDuplicate
      >
        <GoogleApiWrapper
          apiKey={process.env.REACT_APP_GOOGLE_PLACES}
          libraries={['geometry', 'places']}
        >
          <Router>
            <App />
          </Router>
        </GoogleApiWrapper>
      </SnackbarProvider>
    </AppProvider>
  </ThemeConfig>
);
