import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getTokens } from '../shared/utils/helpers';

const NonAuthenticatedRoute = ({ children, ...rest }) => {
  return (
    <Route {...rest}>
      {getTokens().accessToken ? <Redirect to="/jobs" /> : children}
    </Route>
  );
};

NonAuthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default NonAuthenticatedRoute;
