/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { SvgIcon } from '@mui/material';

import Add from '../assets/plus.svg';
import Checkmark from '../assets/checkmark.svg';
import Filter from '../assets/filter.svg';
import Refresh from '../assets/refresh.svg';
import Minus from '../assets/minus.svg';
import Save from '../assets/save-all.svg';
import DeleteTrash from '../assets/delete-trashbin.svg';
import Delete from '../assets/delete-no-bg.svg';
import Edit from '../assets/edit.svg';
import Search from '../assets/search.svg';
import UpArrow from '../assets/up-arrow.svg';
import LeftArrow from '../assets/left-arrow.svg';
import RightArrow from '../assets/right-arrow.svg';
import FirstArrow from '../assets/first-arrow.svg';
import LastArrow from '../assets/last-arrow.svg';
import ViewColumn from '../assets/view-column.svg';

import Rafter from '../assets/trusses/rafter@3x.png';
import Purlin from '../assets/trusses/purlin@3x.png';
import Kingpost from '../assets/trusses/kingpost@3x.png';
import CollarTie from '../assets/trusses/collartie@3x.png';
import DoubleFink from '../assets/trusses/doublefink@3x.png';
import SimpleFink from '../assets/trusses/simplefink@3x.png';
import DoubleHowe from '../assets/trusses/doublehowe@3x.png';
import Fan from '../assets/trusses/fan@3x.png';
import Fink from '../assets/trusses/fink@3x.png';
import Howe from '../assets/trusses/howe@3x.png';
import ModifiedQueen from '../assets/trusses/modifiedqueen@3x.png';
import Queen from '../assets/trusses/queen@3x.png';
import RafterWhite from '../assets/trusses/white/rafter@3x.png';
import PurlinWhite from '../assets/trusses/white/purlin@3x.png';
import KingpostWhite from '../assets/trusses/white/kingpost@3x.png';
import CollarTieWhite from '../assets/trusses/white/collartie@3x.png';
import DoubleFinkWhite from '../assets/trusses/white/doublefink@3x.png';
import SimpleFinkWhite from '../assets/trusses/white/simplefink@3x.png';
import DoubleHoweWhite from '../assets/trusses/white/doublehowe@3x.png';
import FanWhite from '../assets/trusses/white/fan@3x.png';
import FinkWhite from '../assets/trusses/white/fink@3x.png';
import HoweWhite from '../assets/trusses/white/howe@3x.png';
import ModifiedQueenWhite from '../assets/trusses/white/modifiedqueen@3x.png';
import QueenWhite from '../assets/trusses/white/queen@3x.png';

export const DRAWER_WIDTH = 280;
export const MINI_DRAWER_WIDTH = 80;
export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 92;

export const IMAGE_EXTENSIONS = [
  'JPEG',
  'JPG',
  'PNG',
  'GIF',
  'TIFF',
  'TIF',
  'HEIC',
  'HEIF',
  'MP4',
  'MOV',
  'AVI',
  'MPEG'
];

// *** Begin Websocket-related events
export const JOBS_TABLE_EVENTS = [
  // Temporarily disable all websocket events (except new message) on the Jobs table to reduce frequency of the table refreshing
  // 'created',
  // 'edited',
  // 'submitted',
  // 'status_changed',
  // 'revised',
  'new_message'
];
export const STATUS_CHANGES = [
  'calculations_initiated',
  'calculations_failed',
  'calculations_passed'
];
export const FILE_STATUS_CHANGES = ['uploaded', 'failed', 'uploads_finished'];
// *** End Websocket-related events

export const SERVICE_NAMES = [
  { key: 'needs_structural_letter', name: 'Structural Roof Letter' },
  { key: 'needs_structural_stamp', name: 'CAD Stamp (Structural)' },
  { key: 'needs_electrical_stamp', name: 'CAD Stamp (Electrical)' },
  { key: 'needs_ground_mount_letter', name: 'Structural Ground Mount Letter' },
  { key: 'needs_cad_packet', name: 'CAD Design' },
  { key: 'needs_post_install_letter', name: 'Post-Install Letter' },
  { key: 'needs_wall_mount_battery', name: 'Wall Mounted Battery Letter' },
  { key: 'needs_load_calculations', name: 'Load Calculations' },
  { key: 'needs_non_pv_design', name: 'Non-PV Design' },
  { key: 'needs_shade_report', name: 'Shade Report' }
];

export const SERVICE_NAMES_LINE_ITEMS = SERVICE_NAMES.map(item => item.name)
  .concat(['CAD/ENG'])
  .concat(
    SERVICE_NAMES.filter(item => item.key !== 'needs_post_install_letter').map(
      item => `${item.name} (Revision)`
    )
  )
  .concat(['Wet Stamp', 'Wet Affidavit', 'Other']);

export const REVISION_REASONS = [
  { value: 'equipment_change', name: 'Equipment Change' },
  { value: 'electrical_change', name: 'Electrical Change' },
  { value: 'ahj_rejection', name: 'AHJ Rejection' },
  { value: 'utility_rejection', name: 'Utility Rejection' },
  { value: 'as_built', name: 'As-Built' },
  { value: 'company_preference', name: 'Company Preference' },
  { value: 'other', name: 'Other' }
];

export const EVENT_SUBTYPES = {
  'Job Actions': {
    created: 'Created',
    submitted: 'Submitted',
    edited: 'Edited',
    draft: 'Drafted',
    approved: 'Approved',
    assigned: 'Assigned',
    unassigned: 'Unassigned',
    cancelled: 'Cancelled',
    revision: 'Revision',
    deactivated: 'Deactivated'
  },
  'Status Changes': {
    pending_tier_1: 'Pending Tier 1',
    pending_tier_2: 'Pending Tier 1',
    pending_drawing: 'Pending Drawing',
    pending_qc: 'Pending QC',
    qc_rejection: 'QC Rejection',
    pending_design: 'Pending Design',
    pending_design_revision: 'Pending Design Revision',
    waiting_on_ahj: 'Waiting on AHJ',
    pending_client: 'Pending Client',
    pending_pe: 'Pending PE',
    pending_ee: 'Pending EE',
    design_completed: 'Design Completed',
    pending_approval: 'Pending Approval',
    finalized: 'Finalized',
    pending_rae: 'Pending RAE'
  },
  'File Deletions': {
    letter: 'Deleted Letter',
    client_upload: 'Deleted Client Uploaded File',
    cad: 'Deleted CAD File',
    internal: 'Deleted Internal File',
    cad_intake_form: 'Deleted CAD Intake Form',
    misc_finished: 'Deleted Finished File'
  }
};

export const CHART_INTERVALS = [
  'All',
  'Daily',
  'Weekly',
  'Biweekly',
  'Monthly',
  'Quarterly',
  'Biannually',
  'Yearly'
];

export const EMBEDMENT_TYPES = [
  'Roof Metal Sheeting',
  'Roof Sheathing',
  'Roof Substructure',
  'Roof Material',
  '30 ga Steel',
  '29 ga Steel',
  '28 ga Steel',
  '26 ga Steel',
  '24 ga Steel',
  '22 ga Steel',
  '20 ga Steel',
  '18 ga Steel',
  '16 ga Steel',
  '14 ga Steel',
  '12 ga Steel',
  '10 ga Steel',
  '8 ga Steel',
  '6 ga Steel',
  '3/8" OSB',
  '7/16" OSB',
  '1/2" OSB',
  '9/16" OSB',
  '5/8" OSB',
  '3/8" Plywood',
  '7/16" Plywood',
  '1/2" Plywood',
  '9/16" Plywood',
  '5/8" Plywood',
  '1mm Aluminum',
  '.8mm Aluminum',
  '.7mm Aluminum',
  '.05" Aluminum',
  '.04" Aluminum',
  '.032" Aluminum',
  '.027" Aluminum',
  '1mm Zinc',
  '.8mm Zinc',
  '.7mm Zinc',
  '16oz Copper',
  '.7mm Copper',
  '.9mm BMT',
  'Ballast',
  'Tesla Connection'
];
export const ENCLOSURE_TYPES = ['Enclosed', 'Partially Enclosed', 'Open'];
export const GYPSUM_TYPES = [
  { id: '00000000000000000000000000000001', name: '1/2"' },
  { id: '00000000000000000000000000000002', name: '1/4"' },
  { id: '00000000000000000000000000000003', name: '3/8"' },
  { id: '00000000000000000000000000000004', name: '5/8"' },
  { id: '00000000000000000000000000000005', name: 'Lath and Plaster' },
  { id: '00000000000000000000000000000006', name: 'No Drywall' }
];
export const STATUS = ['Draft', 'Submitted', 'Approved', 'Cancelled'];
export const INTERNAL_STATUS = [
  'Pending Drawing',
  'Pending QC',
  'Pending Design',
  'Pending Design Revision',
  'Pending PE',
  'Pending EE',
  'Pending Client',
  'Design Completed',
  'Finalized',
  'Pending Approval',
  'Pending Electrical Check',
  'QC Rejection',
  'Waiting on AHJ'
];

// export const CALC_METHODS = ['Prescriptive', 'Forte', 'SkyCiv'];
export const CALC_METHODS = [
  { name: 'Prescriptive Method (5%)', value: 'Prescriptive Method (5%)' },
  { name: 'Forte', value: 'Forte' },
  { name: 'SkyCiv', value: 'SkyCiv' }
];

export const TRUE_OR_FALSE = ['True', 'False'];
export const ORIENTATION = ['Portrait', 'Landscape'];
export const ROOF_TYPES = ['Gable Roof', 'Hip Roof'];
export const EXPOSURE_CATEGORIES = ['B', 'C', 'D'];
export const AHJ_SEISMIC_CATEGORIES = [
  'A',
  'B',
  'C',
  'D',
  'D0',
  'D1',
  'D2',
  'E'
];
export const AHJ_SITE_CLASSES = ['A', 'B', 'C', 'D', 'E', 'F'];
export const AHJ_REGIONAL_TYPES = ['City', 'County', 'Region', 'Other'];
export const VERIFICATION_OPTIONS = ['Verified', 'Unverified/Expired'];

export const EC_CHOICES = ['NEC', 'CEC'];

export const INTERCONNECTION_LABELS = [
  'Reference Point of Applicability (RPA)',
  'Point of Interconnection'
];

export const STAMP_OPTIONS = [
  'No Stamp Required',
  'Always Include',
  'Only Over 10 Kilowatts'
];

export const PRODUCTION_METER_OPTIONS = [
  'No Production Meter',
  'Always Include',
  'Only Over 10 Kilowatts'
];

export const DOUBLE_ACD_FOR_LST_OPTIONS = [
  'No Double ACD',
  'Always Include',
  'Only Over 10 Kilowatts'
];

export const AC_DISCONNECT_OPTIONS = [
  'Not Required',
  'Always Include',
  'Only Over 10 Kilowatts'
];

export const AC_DISCONNECT_MANUFACTURERS = [
  'Not Required',
  'Square D',
  'Siemens',
  'GE',
  'Eaton'
];

export const LABEL_DIRECTORY_STAMP_LOCATION = [
  '',
  'Utility Meter',
  'Main Panel',
  'Sub Panel',
  'AC Disconnect',
  'Production Meter',
  'Inverter',
  'AC Combiner',
  'Junction Box',
  'Battery',
  'Line Side Disconnect (Double ACD)',
  'Der Side Disconnect (Double ACD)',
  'ESS Disconnect',
  'Point of Interconnection'
];

export const STICKER_OPTIONS = [
  '',
  'Yellow - Production Meter',
  'Yellow - Photovoltaic System Connected',
  'Yellow - Utility PV AC Disconnect (Yellow)',
  'Yellow - Caution Supply/Line Side Connection',
  'Yelow - Caution Possible Backfeed ESS Load Size Backfeed',
  'Red - Caution Solar Generation Utility AC Disconnect',
  'Red - Production Meter',
  'Red - Battery Disconnect',
  'Red - Parallel Generation On Site',
  'Red - Warning: PV Combiner Panel Do Not Add Loads',
  'Red - Battery Disconnect AC DC Voltage Fault Current Calculation Date',
  'Red - Rapid Shutdown Disco',
  'Red - Solar PV Breaker Is Backfed Do Not Relocate',
  'Red - Energy Storage System Qualified Maintenance and Service Provider',
  'Red - Energy Storage System Battery Chemistry',
  'Red - Caution Multiple Sources of Power Lockable AC Generation Source Disconnect',
  'Red - Caution Generation Utility AC Disconnect',
  'Clear - Customer Generator System Disconnect Warning',
  'Clear - Warning Customer Generating Facility',
  'Clear - Warning Turn Off PV ACD Prior to Working Inside Panel',
  'Clear - Warning Dual Power Supply Sources: Utility Grid and PV Solar Electric System',
  'Black - Utility AC Disconnect',
  'Black - Der System Disconnect Switch',
  'Black - Der Meter Disconnect Switch',
  'Black - Dedicated Der Meter',
  'Black - Der Storage Meter Disconnect Switch',
  'Black - Der Storage Meter',
  'Black - No Load To Be Served By This Panel',
  'Black - Caution: Additional Generation Source Interconnected',
  'Black - Overcurrent Protection Device',
  'Black - Utility PV Disconnect Safety Switch ACD',
  'Black - Uni-Directional Meter',
  'Black - Bi-Directional Meter',
  'Black - PV System Meter (Utility Side Voltage On Top Lugs)',
  'Black - Leasing Company PV Production Meter',
  'Black - 3rd Party Owned Battery System Production Meter',
  'Black - Uni-Directional Meter Line Side Disconnect',
  'Black - Uni-Directional Meter Der Side Disconnect',
  'Black - Bi-Directional Meter Line Side Disconnect',
  'Black - Bi-Directional Meter Der Side Disconnect',
  'Black - Open All Isolation Devices To Isolate This Metering Enclosure',
  'Black - Photovoltaic Power Source Breakers Are Backfeeding',
  'Black - Breaker Has Been De-Rated Per NEC 705.12(D)(2)',
  'Black - Max Amps',
  'Orange - Warning Other Power Source Connected Is A Der System',
  'Orange - Warning Service Entrance Is Located Approximately From This Location',
  'Orange - Warning A Generation Source Is Connected To The Supply (Utility) Side Of The Service Disconnecting Means',
  'Orange - Warning Multi Power Production Sources Interconnected To This Electrical Device',
  'Orange - Warning This Equipment Fed By Multiple Sources.',
  'Orange - Warning Power Source Output Connection Do Not Relocate This Overcurrent Device',
  'Orange - Warning - Voltage At This Meter Is Different Than Voltage At Billing Meter',
  'Blue - Notice Dedicated Der System Combiner Panel Do Not Add Loads To This Panel'
];

export const LABEL_DIRECTORY_STAMP_LOGIC = [
  '',
  'Always Include',
  'Only on 10kw+ systems',
  'Only on Taps',
  'Only on Line Side Taps',
  'Only on Load Side Taps',
  'Only when AC Disconnect present',
  'Only when Production Meter present',
  'Only when AC Combiner present',
  'Only when Junction Box present',
  'Only when Battery present',
  'Only for commercial systems',
  'Only when Double ACD present'
];

export const REQUIREMENT_CHIP_GROUPS = {
  engineering: 'Engineering',
  titleBlock: 'Title Block',
  electrical: 'Electrical',
  details: 'Details',
  labels: 'Labels',
  paperSpace: 'Paper Space'
};

export const AVAILABLE_REQUIREMENTS = {
  battery_disconnect: {
    labelName: 'Battery Disconnect',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical',
    helperText: 'Disconnect required for each battery.'
  },
  third_party_stamps: {
    labelName: 'Third Party Stamps',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  wet_stamps: {
    labelName: 'Wet Stamp - 1 Copy',
    type: 'bool',
    defaultValue: true
  },
  wet_stamp_2_copies: {
    labelName: 'Wet Stamp - 2 Copies',
    type: 'bool',
    defaultValue: true
  },
  wet_stamp_3_copies: {
    labelName: 'Wet Stamp - 3 Copies',
    type: 'bool',
    defaultValue: true
  },
  design_affidavit: {
    labelName: 'Design Affidavit',
    type: 'bool',
    defaultValue: true
  },
  unreduced_snow_load: {
    labelName: 'Unreduced Snow Load',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  asphalt_shingle_layers: {
    labelName: 'Layers of Asphalt Shingles',
    type: 'bool',
    defaultValue: true
  },
  elevation: { labelName: 'Elevation', type: 'bool', defaultValue: true },
  framing_plan: {
    labelName: 'Framing Plan',
    type: 'bool',
    defaultValue: true
  },
  taps_not_allowed: {
    labelName: 'Taps Not Allowed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  line_side_taps_not_allowed: {
    labelName: 'Line Side Taps Not Allowed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  load_side_taps_not_allowed: {
    labelName: 'Load Side Taps Not Allowed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  mb_derate_not_allowed: {
    labelName: 'Main Breaker Derate Not Allowed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  neutral_passthrough: {
    labelName: 'Neutral Passthrough',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  ng_bond: { labelName: 'NG Bond', type: 'bool', defaultValue: true },
  attic_photos_on_cad: {
    labelName: 'Attic Photos on CAD',
    type: 'bool',
    defaultValue: true
  },
  structural_stamps_on_cad: {
    labelName: 'Structural Stamps on CAD',
    type: 'select',
    defaultValue: STAMP_OPTIONS[0],
    options: STAMP_OPTIONS,
    chipGroup: 'engineering'
  },
  seismic_calculations: {
    labelName: 'Seismic Calculations',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  professional_stamps_on_electrical: {
    labelName: 'PE Stamps on Electrical',
    type: 'select',
    defaultValue: STAMP_OPTIONS[0],
    options: STAMP_OPTIONS,
    chipGroup: 'engineering'
  },
  electrical_stamps_on_cad: {
    labelName: 'EE Stamps on Electrical',
    type: 'select',
    defaultValue: STAMP_OPTIONS[0],
    options: STAMP_OPTIONS,
    chipGroup: 'engineering'
  },
  good_condition_phrase: {
    labelName: '"Roof Material Appears to be in Good Condition" Phrase',
    type: 'bool',
    defaultValue: true
  },
  post_install_letter: {
    labelName: 'Post-Install Letter',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  appendix_g: {
    labelName: 'Appendix G',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  stress_analysis: {
    labelName: 'Stress Analysis (5% not allowed)',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  show_all_gcp_zones: {
    labelName: 'Show all GCP Zones',
    type: 'bool',
    defaultValue: true
  },
  production_meter: {
    labelName: 'Production Meter',
    type: 'select',
    defaultValue: PRODUCTION_METER_OPTIONS[0],
    options: PRODUCTION_METER_OPTIONS,
    chipGroup: 'ac'
  },
  fused_acd_required: {
    labelName: 'Fused Disconnect Required',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  separate_pages: {
    labelName: 'Separate',
    type: 'bool',
    defaultValue: true,
    helperText: 'Roof Letter and Design signed in separate files'
  },
  combined_pages: {
    labelName: 'Combined',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Combine Roof Letter and Design in one PDF, use the Digital Signature on Page 2 of Roof Letter and Sign the same page'
  },
  split_pages: {
    labelName: 'Split',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Rename all pages and add both Digital Signature and Third-Party Signature'
  },
  split_planset_by_discipline: {
    labelName: 'Split Planset by Discipline',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Split the content of the planset into separate files based on discipline (structural vs. electrical).'
  },
  electrical_photos_on_cad: {
    labelName: 'Electrical Photos on CAD',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  double_acd_for_lst: {
    labelName: 'Double ACD for LST',
    type: 'select',
    defaultValue: DOUBLE_ACD_FOR_LST_OPTIONS[0],
    options: DOUBLE_ACD_FOR_LST_OPTIONS,
    chipGroup: 'electrical'
  },
  ac_disconnect: {
    labelName: 'AC Disconnect',
    type: 'select',
    defaultValue: AC_DISCONNECT_OPTIONS[1],
    options: AC_DISCONNECT_OPTIONS,
    chipGroup: 'electrical'
  },
  ac_disconnect_label: {
    labelName: 'AC Disconnect Label',
    type: 'text',
    defaultValue: '',
    helperText: '',
    chipGroup: 'electrical'
  },
  second_ac_disconnect_label: {
    labelName: '2nd AC Disconnect Label',
    type: 'text',
    defaultValue: '',
    helperText: '',
    chipGroup: 'electrical'
  },
  see_structural_criteria_roofing_letter: {
    labelName: 'See Structural Criteria Roofing Letter',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  utility_meter_top_fed: {
    labelName: 'Utility Meter Top Fed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  add_grounding_rod: {
    labelName: 'Add Grounding Rod',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  meter_taps_allowed: {
    labelName: 'Meter Taps Allowed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  meter_taps_not_allowed: {
    labelName: 'Meter Taps Not Allowed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  production_meter_top_fed: {
    labelName: 'Production Meter Top Fed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  production_meter_bottom_fed: {
    labelName: 'Production Meter Bottom Fed',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  sld_uploaded: {
    labelName: 'SLD Uploaded',
    type: 'bool',
    defaultValue: true
  },
  disconnect_before_meter: {
    labelName: 'PM between ACD and MSP',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  disconnect_after_meter: {
    labelName: 'PM between PV and ACD',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'electrical'
  },
  NABCEP_stamp: {
    labelName: 'NABCEP Stamp',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'engineering'
  },
  ac_disconnect_distance_to_meter: {
    labelName: 'AC Disconnect Distance to Meter',
    type: 'int',
    defaultValue: 10,
    chipGroup: 'electrical'
  },
  title_block_sop: {
    labelName: 'Title Block Scope of Work',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'titleBlock'
  },
  title_block_codes_and_standards: {
    labelName: 'Title Block Codes and Standards',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'titleBlock'
  },
  title_block_ahj_stamp: {
    labelName: 'Title Block AHJ Stamp',
    type: 'bool',
    defaultValue: true,
    chipGroup: 'titleBlock'
  },
  title_block_contractor_stamp: {
    labelName: 'Title Block Contractor Stamp',
    type: 'file',
    defaultValue: '',
    helperText: 'Upload a 750x500 pixel image for the best results',
    chipGroup: 'titleBlock'
  },
  title_block_engineering_stamp: {
    labelName: 'Title Block Engineering Stamp',
    type: 'bool',
    defaultValue: true,
    helperText:
      '3"X3" space on the title block for an engineering stamp. Adding this requirement will delete the contractor stamp.',
    chipGroup: 'titleBlock'
  },
  combiner_box_file: {
    labelName: 'Combiner Box File',
    type: 'file',
    defaultValue: '',
    chipGroup: 'electrical'
  },
  dwg_electrical_code: {
    labelName: 'DWG Electrical Code',
    type: 'select',
    defaultValue: EC_CHOICES[0],
    options: EC_CHOICES,
    chipGroup: 'electrical'
  },
  dwg_interconnection_label: {
    labelName: 'DWG Interconnection Label',
    type: 'select',
    defaultValue: INTERCONNECTION_LABELS[1],
    options: INTERCONNECTION_LABELS,
    chipGroup: 'labels'
  },
  rail_length: {
    labelName: 'Rail Length',
    type: 'float',
    defaultValue: 14,
    helperText: 'Enter the rail length in feet.'
  },
  ahj_stamp_required: {
    labelName: 'AHJ Stamp Required',
    type: 'bool',
    defaultValue: true
  },
  fence_line_required: {
    labelName: 'Fence Line Required',
    type: 'bool',
    defaultValue: true
  },
  property_lines_required: {
    labelName: 'Property Lines Required',
    type: 'bool',
    defaultValue: true
  },
  draw_rafters: {
    labelName: 'Rafters Required',
    type: 'bool',
    defaultValue: true
  },
  alternate_page_naming: {
    labelName: 'Alternate Page Naming',
    type: 'bool',
    defaultValue: true,
    helperText: 'Will change page numbering to "T01, A01, A02, E01, E02" etc.',
    chipGroup: 'labels'
  },
  alternate_page_naming_2: {
    labelName: 'Alternate Page Naming 2',
    type: 'bool',
    defaultValue: true,
    helperText: 'Will change page numbering to "A01, S01, E02, DS01 etc',
    chipGroup: 'labels'
  },
  wind_load_detail: {
    labelName: 'Wind Load Detail',
    type: 'bool',
    defaultValue: true,
    helperText: '',
    chipGroup: 'details'
  },
  roof_layout_detail: {
    labelName: 'Roof Layout Detail',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Adds an additional paperspace for a roof layout specific page.',
    chipGroup: 'details'
  },
  title_block_system_specs: {
    labelName: 'Title Block System Specs',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Add system size, module type, and inverter type onto title block.',
    chipGroup: 'titleBlock'
  },
  one_line_diagram: {
    labelName: 'One Line Diagram',
    type: 'bool',
    defaultValue: true,
    helperText: '',
    chipGroup: 'details'
  },
  trees_on_property: {
    labelName: 'Trees on Property',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Include warning note for designer to draw trees that are on the property'
  },
  pv0: {
    labelName: 'PV 0',
    type: 'bool',
    defaultValue: true,
    helperText: '',
    chipGroup: 'paperspace'
  },
  pv_back_breaker_rating_note: {
    labelName: 'PV Back Breaker Rating Note',
    type: 'bool',
    defaultValue: true,
    helperText: '10000aic rated',
    chipGroup: 'electrical'
  },
  junction_box_under_array_callout: {
    labelName: 'Junction Box Located Under Array Callout',
    type: 'bool',
    defaultValue: true,
    helperText: 'Includes "located under array" on the junction box callout',
    chipGroup: 'electrical'
  },
  line_side_tap_box: {
    labelName: 'Line Side Tap Box',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Swaps line side tap interconnections out with a line side tap box.',
    chipGroup: 'electrical'
  },
  production_meter_label: {
    labelName: 'Production Meter Label',
    type: 'text',
    defaultValue: '',
    helperText: '',
    chipGroup: 'labels'
  },
  production_meter_type: {
    labelName: 'Production Meter Type',
    type: 'text',
    defaultValue: '',
    helperText: '',
    chipGroup: 'labels'
  },
  production_meter_cut_sheet: {
    labelName: 'Production Meter Cut Sheet',
    type: 'bool',
    defaultValue: true,
    helperText: 'Include a cut sheet for the production meter.',
    chipGroup: 'electrical'
  },
  span_tables: {
    labelName: 'Span Tables',
    type: 'bool',
    defaultValue: true,
    helperText: 'Include a racking span tables on the design'
  },
  conduit_naming: {
    labelName: 'Electrical Conduit Naming',
    type: 'text',
    defaultValue: 'EMT',
    helperText: 'Change the electrical conduit naming from the default "EMT".',
    chipGroup: 'labels'
  },
  conduit_sizing: {
    labelName: 'Electrical Conduit Sizing',
    type: 'text',
    defaultValue: '',
    helperText: 'Change the electrical conduit size from default sizing',
    chipGroup: 'labels'
  },
  acd_model_number: {
    labelName: 'AC Disconnect Model Number',
    type: 'select',
    defaultValue: AC_DISCONNECT_MANUFACTURERS[0],
    helperText: 'Include AC Disconnect Model Number on electrical diagram.',
    options: AC_DISCONNECT_MANUFACTURERS,
    chipGroup: 'electrical'
  },
  title_block_parcel_number: {
    labelName: 'Title Block Parcel id/APN',
    type: 'bool',
    defaultValue: true,
    helperText:
      'Include the parcel id/APN on the title block. This will be pulled from the project address.',
    chipGroup: 'titleBlock'
  },
  case_number: {
    labelName: 'Application Case Number',
    type: 'bool',
    defaultValue: true,
    helperText: '',
    dynamic_cad_fields: [
      {
        key: 'case_number',
        label: 'Case Number',
        type: 'text',
        defaultValue: '',
        helperText: ''
      }
    ]
  },
  custom_applicable_codes: {
    labelName: 'Custom Applicable Codes',
    type: 'group',
    defaultValue: {},
    chipGroup: 'details',
    fields: {
      custom_code_1: {
        labelName: 'Custom Applicable Code 1',
        type: 'text',
        defaultValue: '',
        helperText:
          'Convention is the code year followed by the name of the code.'
      },
      custom_code_2: {
        labelName: 'Custom Applicable Code 2',
        type: 'text',
        defaultValue: '',
        helperText:
          'Convention is the code year followed by the name of the code.'
      }
    }
  },
  elevation_detail: {
    labelName: 'Elevation Detail',
    type: 'group',
    defaultValue: {},
    chipGroup: 'details',
    fields: {
      elevation_detail_required: {
        labelName: 'Elevation Detail Required',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include an elevation detail page in the drawing.'
      },
      utility_notes: {
        labelName: 'Utility Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Include a utility notes section. Separate points by a semi-colon (;)',
        lineCount: 3
      }
    }
  },
  wire_size: {
    labelName: 'Wire Size',
    type: 'group',
    defaultValue: {},
    chipGroup: 'electrical',
    fields: {
      ac_wire_size: {
        labelName: 'AC Wire Size',
        type: 'int',
        defaultValue: null,
        helperText:
          'Leave empty for it to be calculated according to NEC standards.'
      },
      ac_ground_size: {
        labelName: 'AC Ground Size',
        type: 'int',
        defaultValue: null,
        helperText:
          'Leave empty for it to be calculated according to NEC standards.'
      },
      dc_wire_size: {
        labelName: 'DC Wire Size',
        type: 'int',
        defaultValue: null,
        helperText:
          'Leave empty for it to be calculated according to NEC standards.'
      },
      dc_ground_size: {
        labelName: 'DC Ground Size',
        type: 'int',
        defaultValue: null,
        helperText:
          'Leave empty for it to be calculated according to NEC standards.'
      }
    }
  },
  pv1: {
    labelName: 'PV 1',
    type: 'group',
    defaultValue: {},
    chipGroup: 'paperspace',
    fields: {
      revision_table: {
        labelName: 'Revision Table',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include a revision table within the title block on PV-1.'
      },
      esid: {
        labelName: 'ESID',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include an ESID field on the PV-1 title block.'
      },
      meter_number: {
        labelName: 'Meter Number',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include a Meter Number field on the PV-1 title block.'
      },
      large_ahj_stamp: {
        labelName: 'Large AHJ Stamp',
        type: 'bool',
        defaultValue: true,
        helperText: ''
      },
      tmk_number: {
        labelName: 'TMK Number',
        type: 'bool',
        defaultValue: true,
        helperText: '',
        dynamic_cad_fields: [
          {
            key: 'tmk',
            label: 'TMK Number',
            type: 'text',
            defaultValue: '',
            helperText: ''
          }
        ]
      },
      number_of_stories: {
        labelName: 'Number of Stories',
        type: 'bool',
        defaultValue: true,
        helperText:
          'Include number of stories field in the title block on PV-1',
        dynamic_cad_fields: [
          {
            key: 'num_stories',
            label: 'Number of Stories',
            type: 'text',
            defaultValue: '',
            helperText: ''
          }
        ]
      },
      utility_notes: {
        labelName: 'Utility Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Include a utility notes section. Separate points by a semi-colon (;)',
        lineCount: 3
      },
      warning_notes: {
        labelName: 'Warning Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a red warning note below the paper-space in the bottom left corner.',
        lineCount: 3
      },
      extra_notes: {
        labelName: 'Extra Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a customizable note above the paper-space in the top right corner. Separate each line by a semi-colon (;)',
        lineCount: 3
      },
      custom_leaders: {
        labelName: 'Custom Leaders',
        type: 'text',
        defaultValue: '',
        helperText: 'Separate each leader text with a semi-colon(;)',
        lineCount: 3
      },
      general_notes: {
        labelName: 'General Notes',
        type: 'text',
        defaultValue:
          'This project involves the installation of a grid-interactive PV system. PV modules will be mounted using a pre-engineered mounting system. The modules will be electrically connected to the local utility using means and methods consistent with the rules enforced by the local utility and authority having jurisdiction.;This document has been prepared to describe the design of a proposed pv system with enough detail to demonstrate compliance with applicable codes and regulations. The document shall not be relied upon as a substitute for following manufacturer installation instructions. The system shall comply with all manufacturer installation instructions, as well as all applicable codes. Nothing in this document shall be interpreted in a way that overrides them. The contractor is responsible for verification of all details in this document.;This document is based on field inspections and other information available at the time. Actual field conditions may vary and require modifications in construction details.;The dimensions and measurements shown in this document are based on aerial imagery and other available information, and are to be treated as approximated to show a general idea of equipment location and property size.;Any changes to this design at the time of installation due to field conditions must be reported to the designer and AHJ.',
        helperText: '',
        lineCount: 3
      }
    }
  },
  pv2: {
    labelName: 'PV 2',
    type: 'group',
    defaultValue: {},
    chipGroup: 'paperspace',
    fields: {
      building_permit_number: {
        labelName: 'Building Permit Number',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include the building permit number on PV-4A.',
        dynamic_cad_fields: [
          {
            key: 'building_permit_number',
            label: 'Building Permit Number',
            type: 'text',
            defaultValue: '',
            helperText: ''
          }
        ]
      },
      parcel_legal_description: {
        labelName: 'Parcel Legal Description',
        type: 'bool',
        defaultValue: true,
        helperText:
          'Include Parcel ID, County ID, legal description, etc on PV2'
      },
      roof_information: {
        labelName: 'Roof Information',
        type: 'bool',
        defaultValue: true,
        helperText: ''
      },
      array_load_calculations: {
        labelName: 'Array Load Calculations',
        type: 'bool',
        defaultValue: true,
        helperText: ''
      },
      utility_notes: {
        labelName: 'Utility Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Include a utility notes section. Separate points by a semi-colon (;)',
        lineCount: 3
      },
      warning_notes: {
        labelName: 'Warning Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a red warning note below the paper-space in the bottom left corner.',
        lineCount: 3
      },
      extra_notes: {
        labelName: 'Extra Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a customizable note above the paper-space in the top right corner. Separate each line by a semi-colon (;)',
        lineCount: 3
      },
      custom_leaders: {
        labelName: 'Custom Leaders',
        type: 'text',
        defaultValue: '',
        helperText: 'Separate each leader text with a semi-colon(;)',
        lineCount: 3
      }
    }
  },
  pv3: {
    labelName: 'PV 3',
    type: 'group',
    defaultValue: {},
    chipGroup: 'paperspace',
    fields: {
      warning_notes: {
        labelName: 'Warning Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a red warning note below the paper-space in the bottom left corner.',
        lineCount: 3
      },
      extra_notes: {
        labelName: 'Extra Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a customizable note above the paper-space in the top right corner. Separate each line by a semi-colon (;)',
        lineCount: 3
      }
    }
  },
  pv4: {
    labelName: 'PV 4',
    type: 'group',
    defaultValue: {},
    chipGroup: 'paperspace',
    fields: {
      utility_program: {
        labelName: 'Utility Program',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include the utility energy program on PV-4A.',
        dynamic_cad_fields: [
          {
            key: 'utility_program',
            label: 'Utility Program',
            type: 'text',
            defaultValue: '',
            helperText: ''
          }
        ]
      },
      bill_of_materials: {
        labelName: 'Bill of Materials',
        type: 'bool',
        defaultValue: true,
        helperText:
          'Include a bill of materials in the top right corner of pv4.'
      },
      esid: {
        labelName: 'ESID',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include an ESID field on the PV-4 title block.',
        dynamic_cad_fields: [
          {
            key: 'esid',
            label: 'ESID',
            type: 'text',
            defaultValue: '',
            helperText: ''
          }
        ]
      },
      meter_number: {
        labelName: 'Meter Number',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include a Meter Number field on the PV-4 title block.',
        dynamic_cad_fields: [
          {
            key: 'meter_number',
            label: 'Meter Number',
            type: 'text',
            value: '',
            helperText: ''
          }
        ]
      },
      terminator_caps: {
        labelName: 'Terminator Caps on Bill of Materials',
        type: 'bool',
        defaultValue: true,
        helperText:
          'Include a row on the bill of materials including the number of system terminator caps.'
      },
      raceway_location_table: {
        labelName: 'Raceway Location Table',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include a Raceway Location table on PV-4.'
      },
      yellow_utility_meter_sticker: {
        labelName: 'Yellow Utility Meter Sticker',
        type: 'bool',
        defaultValue: true,
        helperText: ''
      },
      yellow_main_billing_sticker: {
        labelName: 'Yellow Main Billing Sticker',
        type: 'bool',
        defaultValue: true,
        helperText: ''
      },
      emergency_contact_sticker: {
        labelName: 'Emergency Contact Sticker',
        type: 'bool',
        defaultValue: true,
        helperText: ''
      },
      calculation_120_rule: {
        labelName: '120% Rule Calculation',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include 120% rule calculation table on PV-4'
      },
      system_output_current_calculation: {
        labelName: 'System Output Current Calculation',
        type: 'bool',
        defaultValue: true,
        helperText: 'Include PV system output current calculation table on PV-4'
      },
      gas_meter_electrical_note: {
        labelName: 'Gas Meter Electrical Note',
        type: 'bool',
        defaultValue: true,
        helperText: "Note: No electrical within 3' of gas meter."
      },
      ac_disconnect_meter_note: {
        labelName: 'AC Disconnect Meter Note',
        type: 'bool',
        defaultValue: true,
        helperText: "Note: AC Disconnect within 10' of meter."
      },
      module_specs: {
        labelName: 'Module Specification Table',
        type: 'bool',
        defaultValue: true,
        helperText: ''
      },
      micro_combiner_panel_amperage: {
        labelName: 'Micro Combiner Panel Amperage',
        type: 'int',
        defaultValue: 125,
        helperText: 'Change default amperage of micro-inverter combiner panels.'
      },
      dual_micro_combiner_panel_amperage: {
        labelName: 'Dual Micro Combiner Panel Amperage',
        type: 'int',
        defaultValue: 125,
        helperText:
          'Change default amperage of dual micro-inverter combiner panels.'
      },
      utility_notes: {
        labelName: 'Utility Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Include a utility notes section. Separate points by a semi-colon (;)',
        lineCount: 3
      },
      electrical_notes: {
        labelName: 'Electrical Notes',
        type: 'text',
        defaultValue:
          'All equipment to be listed by UL or other NRTL, and labeled for its application;All raceways on rooftops shall be placed more than 7/8" above the rooftop. All conductors shall be copper, rated for 600v and 90°c wet environment;Wiring, conduit, and raceways mounted on rooftops shall be routed directly to, and located as close as possible to the nearest ridge, hip, or valley;Working clearances around all new and existing electrical equipment shall comply with nec 110.26;Drawings indicate the general arrangement of systems;Contractor shall furnish all necessary outlets, supports, fittings, and accessories to fulfill applicable codes and standards;Where sizes of junction boxes, raceways, and conductors are not specified, the contractor shall size them accordingly;Array grounding to be installed per racking manufacturer\'s instruction;Array racking to be bonded with continuous copper e.g.c. via weeb lug or ilsco gbl-4dbt lay-in lug;The polarity of the grounded conductors is negative.;Surge protection required per nec 230.67',
        helperText:
          'Include custom electrical notes, default notes are ones in the box above. Make sure to separate bullet points with semi-colons (;)',
        lineCount: 3
      },
      warning_notes: {
        labelName: 'Warning Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a red warning note below the paper-space in the bottom left corner.',
        lineCount: 3
      },
      extra_notes: {
        labelName: 'Extra Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a customizable note above the paper-space in the top right corner. Separate each line by a semi-colon (;)',
        lineCount: 3
      }
    }
  },
  pv5: {
    labelName: 'PV 5',
    type: 'group',
    defaultValue: {},
    chipGroup: 'paperspace',
    fields: {
      orange_shock_hazard_sticker_removal: {
        labelName: 'Orange Shock Hazard Sticker Removal',
        type: 'bool',
        defaultValue: true
      },
      cp_nel_sticker: {
        labelName: 'CP NEL Sticker',
        type: 'bool',
        defaultValue: true
      },
      parallel_generation_sticker: {
        labelName: 'Parallel Generation Sticker',
        type: 'bool',
        defaultValue: true
      },
      solar_generation_acd_caution_sticker: {
        labelName: 'Solar Generation ACD Caution Sticker',
        type: 'bool',
        defaultValue: true
      },
      um_pv_system_connected_sticker: {
        labelName: 'Utility Meter PV System Connected Sticker',
        type: 'bool',
        defaultValue: true
      },
      yellow_pv_acd_sticker: {
        labelName: 'Yellow PV ACD Sticker',
        type: 'bool',
        defaultValue: true
      },
      ess_kwh_capacity_sticker: {
        labelName: 'ESS KWH Capacity Sticker',
        type: 'bool',
        defaultValue: true
      },
      yellow_system_disconnects_diagram: {
        labelName: 'System Disconnects Diagram Yellow Sticker',
        type: 'bool',
        defaultValue: true
      },
      attic_photos: {
        labelName: 'Attic Photos',
        type: 'bool',
        defaultValue: true,
        helperText:
          'Include an additional paperpsace for attic and house photos.',
        chipGroup: 'details'
      },
      extra_leaders: {
        labelName: 'Extra Leaders',
        type: 'bool',
        defaultValue: true,
        helperText:
          'Driveway, PV Array, and ESS Disconnect leaders for the directory plaque.'
      },
      title_block_label_notes: {
        labelName: 'Title Block Label Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a red warning note below the paper-space in the bottom left corner.',
        lineCount: 3
      },
      extra_notes: {
        labelName: 'Extra Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a customizable note above the paper-space in the top right corner. Separate each line by a semi-colon (;)',
        lineCount: 3
      },
      custom_leaders: {
        labelName: 'Custom Leaders',
        type: 'text',
        defaultValue: '',
        helperText: 'Separate each leader text with a semi-colon(;)',
        lineCount: 3
      },
      warning_notes: {
        labelName: 'Warning Notes',
        type: 'text',
        defaultValue: '',
        helperText:
          'Generates a red warning note below the paper-space in the bottom left corner.',
        lineCount: 3
      }
    }
  },
  cut_sheet_spec_notes: {
    labelName: 'Cut Sheet Spec Notes',
    type: 'text',
    defaultValue:
      'All electrical equipment shall be certified according to ahj and national requirements by a nationally recognized testing laboratory (NRTL).;For documentation showing NRTL certification, see manufacturer website.;All equipment shall be installed according to manufacturer instruction.'
  },
  extra_cut_sheets: {
    labelName: 'Extra Cut Sheets',
    type: 'group',
    defaultValue: {},
    fields: {
      pv8a: {
        labelName: 'PV 8 A',
        type: 'file',
        defaultValue: '',
        helperText: ''
      },
      pv11: {
        labelName: 'PV 11',
        type: 'file',
        defaultValue: '',
        helperText: ''
      },
      pv12: {
        labelName: 'PV 12',
        type: 'file',
        defaultValue: '',
        helperText: ''
      },
      pv13: {
        labelName: 'PV 13',
        type: 'file',
        defaultValue: '',
        helperText: ''
      },
      pv14: {
        labelName: 'PV 14',
        type: 'file',
        defaultValue: '',
        helperText: ''
      },
      pv15: {
        labelName: 'PV 15',
        type: 'file',
        defaultValue: '',
        helperText: ''
      },
      noa_cut_sheets: {
        labelName: 'Miami-Dade NOA Cut Sheets',
        type: 'bool',
        default: true,
        helperText: 'Include Miami-Dade NOA cut sheets in the plan set.'
      },
      ul_1703_cut_sheets: {
        labelName: 'UL 1703 Cut Sheets',
        type: 'bool',
        default: true,
        helperText: 'Include UL 1703 cut sheets in the plan set.'
      }
    }
  },
  extra_stamps: {
    labelName: 'Extra Custom Stamps',
    type: 'group',
    defaultValue: {},
    fields: {
      stamp_1: {
        labelName: 'Stamp 1',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_1_location: {
        labelName: 'Stamp 1 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_1_logic: {
        labelName: 'Stamp 1 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_2: {
        labelName: 'Stamp 2',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_2_location: {
        labelName: 'Stamp 2 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_2_logic: {
        labelName: 'Stamp 2 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_3: {
        labelName: 'Stamp 3',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_3_location: {
        labelName: 'Stamp 3 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_3_logic: {
        labelName: 'Stamp 3 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_4: {
        labelName: 'Stamp 4',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_4_location: {
        labelName: 'Stamp 4 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_4_logic: {
        labelName: 'Stamp 4 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_5: {
        labelName: 'Stamp 5',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_5_location: {
        labelName: 'Stamp 5 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_5_logic: {
        labelName: 'Stamp 5 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_6: {
        labelName: 'Stamp 6',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_6_location: {
        labelName: 'Stamp 6 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_6_logic: {
        labelName: 'Stamp 6 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_7: {
        labelName: 'Stamp 7',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_7_location: {
        labelName: 'Stamp 7 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_7_logic: {
        labelName: 'Stamp 7 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_8: {
        labelName: 'Stamp 8',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_8_location: {
        labelName: 'Stamp 8 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_8_logic: {
        labelName: 'Stamp 8 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      },
      stamp_9: {
        labelName: 'Stamp 9',
        type: 'select',
        defaultValue: STICKER_OPTIONS[0],
        options: STICKER_OPTIONS
      },
      stamp_9_location: {
        labelName: 'Stamp 9 Location',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOCATION[0],
        options: LABEL_DIRECTORY_STAMP_LOCATION
      },
      stamp_9_logic: {
        labelName: 'Stamp 9 Logic',
        type: 'select',
        defaultValue: LABEL_DIRECTORY_STAMP_LOGIC[0],
        options: LABEL_DIRECTORY_STAMP_LOGIC
      }
    }
  }
};

export const MIN_MSP_RATING = 100;
export const MAX_MSP_RATING = 1200;
export const BATTERY_BACKUP_TYPES = [
  'Non-Backup',
  'Whole Home',
  'Partial Home'
];
export const INTERNAL_INTERCONNECTION_OPTIONS = [
  'Breaker Injection into MSP',
  'Breaker in MSP Center Feed',
  'Breaker in MSP Top Feed',
  'Supply Side Tap',
  'Supply Side Tap in Meter Can',
  'Supply Side Tap in Tap Box',
  'Supply Side Tap No EGC',
  'Supply Side Tap In Meter Can No EGC',
  'Supply Side Tap In Tap Box No EGC',
  'Supply Side Tap No EGC Neutral Included',
  'Supply Side Tap In Meter Can No EGC Neutral Included',
  'Supply Side Tap In Tap Box No EGC Neutral Included',
  'Load Side Tap',
  'Load Side Tap New Sub Panel',
  'Load Side Tap New Fusible Disconnect',
  'GMA',
  'Breaker in MLO MSP',
  'Breaker in Line Side Slot',
  'Breaker in Sub Panel',
  'Sub Panel Re-Route',
  'Sub Panel Re-Route 2 Sub Panels',
  'Sub Panel Re-Route 1',
  'Sub Panel Re-Route 2',
  'Line Side Tap Box',
  'Fusible Disconnect Add',
  'Hawaiian Tie In'
];
export const CLIENT_INTERCONNECTION_OPTIONS = [
  'Breaker Injection into MSP',
  'Supply Side Tap',
  'Load Side Tap'
];
export const PRODUCTION_METER_LOCATIONS = [
  'Not Applicable',
  'Between Solar and Disconnect',
  'Between Disconnect and MSP'
];
export const PREFERRED_RACKING_MANUFACTURER_OPTIONS = [
  'Ironridge (recommended)',
  'Unirac',
  'SnapNrack',
  'Other (specified in Job notes)'
];
export const FOUNDATION_TYPE_OPTIONS = ['Concrete Foundation', 'Ground Screw'];

export const FRAME_SHAPES = [
  {
    name: 'Non Standard',
    members: 0,
    hasKingPost: true,
    isTruss: true,
    imageUrl: '',
    whiteImageUrl: ''
  },
  {
    name: 'Rafter',
    members: 1,
    hasKingPost: false,
    isTruss: false,
    imageUrl: `url(${Rafter})`,
    whiteImageUrl: `url(${RafterWhite})`
  },
  // half of these standard trusses have a central post, let's call it a "kingpost"
  {
    name: 'Kingpost',
    members: 1,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${Kingpost})`,
    whiteImageUrl: `url(${KingpostWhite})`
  },
  {
    name: 'Collar Tie',
    members: 1,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${CollarTie})`,
    whiteImageUrl: `url(${CollarTieWhite})`
  },
  {
    name: 'Simple Fink',
    members: 2,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${SimpleFink})`,
    whiteImageUrl: `url(${SimpleFinkWhite})`
  },
  {
    name: 'Queen',
    members: 3,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${Queen})`,
    whiteImageUrl: `url(${QueenWhite})`
  },
  {
    name: 'Fink',
    members: 4,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${Fink})`,
    whiteImageUrl: `url(${FinkWhite})`
  },
  {
    name: 'Howe',
    members: 5,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${Howe})`,
    whiteImageUrl: `url(${HoweWhite})`
  },
  {
    name: 'Fan',
    members: 6,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${Fan})`,
    whiteImageUrl: `url(${FanWhite})`
  },
  {
    name: 'Modified Queen',
    members: 7,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${ModifiedQueen})`,
    whiteImageUrl: `url(${ModifiedQueenWhite})`
  },
  {
    name: 'Double Fink',
    members: 8,
    hasKingPost: false,
    isTruss: true,
    imageUrl: `url(${DoubleFink})`,
    whiteImageUrl: `url(${DoubleFinkWhite})`
  },
  {
    name: 'Double Howe',
    members: 9,
    hasKingPost: true,
    isTruss: true,
    imageUrl: `url(${DoubleHowe})`,
    whiteImageUrl: `url(${DoubleHoweWhite})`
  },
  {
    name: 'Purlin',
    members: 1,
    hasKingPost: false,
    isTruss: false,
    imageUrl: `url(${Purlin})`,
    whiteImageUrl: `url(${PurlinWhite})`
  }
];

export const ROLES = [
  { name: 'Admin', value: 'admin' },
  { name: 'Support', value: 'support' },
  { name: 'User', value: 'user' },
  { name: 'Company Admin', value: 'user_admin' },
  { name: 'Contractor', value: 'contractor' }
];
export const ALL_ROLES = [
  'admin',
  'support',
  'user',
  'user_admin',
  'contractor'
];
export const INTERNAL_ROLES = ['admin', 'support'];

export const TABLE_ICONS = {
  Add: forwardRef((props, ref) => (
    <SvgIcon component={Add} {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => (
    <SvgIcon component={Checkmark} {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref) => (
    <SvgIcon component={Delete} {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => (
    <SvgIcon component={DeleteTrash} {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <SvgIcon component={RightArrow} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <SvgIcon component={Edit} {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref) => (
    <SvgIcon component={Save} color="action" {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <SvgIcon component={Filter} {...props} />),
  FirstPage: forwardRef((props, ref) => (
    <SvgIcon component={FirstArrow} {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <SvgIcon component={LastArrow} {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <SvgIcon component={RightArrow} {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <SvgIcon component={LeftArrow} {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <SvgIcon component={Delete} {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <SvgIcon component={Search} {...props} />),
  SortArrow: forwardRef((props, ref) => (
    <SvgIcon component={UpArrow} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <SvgIcon component={Minus} {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <SvgIcon component={ViewColumn} color="action" {...props} ref={ref} />
  )),
  Retry: forwardRef((props, ref) => (
    <SvgIcon component={Refresh} {...props} ref={ref} />
  ))
};

export const DEFAULT_ARRAY_DATA = {
  panel_quantity: '',
  roof_material: '',
  connection_type: '',
  custom_connection_type: null,
  beam_spacing: 24,
  num_panels_on_rafter: '',
  height_from_ground: 20,
  panel_to_eave: 0,
  orientation: ORIENTATION[0],
  frame_shape: '',
  nonstandard_framing: false,
  custom_beam_shape: null,
  roof_pitch: 26,
  roof_overhang: 18,
  ballast_weight: 0,
  module_type: '',
  racking_type: '',
  custom_racking_type: null,
  beam_span: [168],
  beam_shape: '',
  collar_tie: 30,
  truss_builder_coords: {}
};

export const DEFAULT_JOB_FORM_DATA = {
  name: '',
  client_name: '',
  client_street_address: '',
  company: '',
  home_elevation: null,
  company_verified_inspection: false,
  no_pictures: false,
  roof_type: ROOF_TYPES[0],
  gypsum_type: GYPSUM_TYPES[5],
  status: 'Draft',
  enclosed: ENCLOSURE_TYPES[0],
  exposure_category: null,
  notes: '',
  needs_structural_letter: false,
  needs_ground_mount_letter: false,
  needs_structural_stamp: false,
  needs_electrical_stamp: false,
  needs_cad_packet: false,
  needs_post_install_letter: false,
  job_files_link: '',
  client_zip_code: '',
  arrays: []
};

export const CAD_FIELD_NAMES = {
  general_cad_fields: [
    'needs_structural_cad',
    'needs_ground_mount',
    'needs_battery_info',
    'needs_optimizer',
    'needs_mci',
    'main_breaker_derate',
    'module_type',
    'custom_module_type',
    'module_count',
    'inverter_type',
    'custom_inverter_type',
    'inverter_count',
    'mpu_required',
    'msp_rating',
    'msp_interior',
    'breaker_rating',
    'interconnection',
    'cad_notes',
    'contractor_license_num',
    'utility',
    'custom_utility',
    'production_meter_location'
  ],
  optimizer_fields: [
    'optimizer_type',
    'custom_optimizer_type',
    'optimizer_count'
  ],
  mci_fields: ['mci', 'mci_count'],
  mpu_required_fields: ['mpu_model_number'],
  battery_cad_fields: [
    'battery_type',
    'custom_battery_type',
    'battery_count',
    'battery_backup'
  ],
  structural_cad_fields: [
    'roof_material',
    'racking_type',
    'custom_racking_type',
    'connection_type',
    'custom_connection_type',
    'roof_pitch',
    'beam_shape',
    'custom_beam_shape',
    'beam_spacing'
  ],
  ground_mount_fields: [
    'racking_pitch',
    'preferred_racking_manufacturer',
    'foundation_type'
  ]
};

export const DEFAULT_CAD_INTAKE_DATA = {
  needs_structural_cad: true,
  needs_ground_mount: false,
  needs_battery_info: false,
  needs_optimizer: true,
  needs_mci: false,
  main_breaker_derate: false,
  roof_material: '',
  roof_pitch: '',
  racking_type: '',
  custom_racking_type: null,
  connection_type: '',
  custom_connection_type: null,
  module_type: '',
  custom_module_type: null,
  module_count: '',
  inverter_type: '',
  custom_inverter_type: null,
  inverter_count: '',
  optimizer_type: '',
  custom_optimizer_type: null,
  optimizer_count: '',
  battery_type: '',
  custom_battery_type: null,
  mci: '',
  mci_count: '',
  battery_count: '',
  battery_backup: '',
  mpu_required: false,
  mpu_model_number: '',
  msp_rating: '',
  msp_interior: false,
  breaker_rating: '',
  interconnection: '',
  racking_pitch: '',
  preferred_racking_manufacturer: '',
  cad_notes: '',
  contractor_license_num: '',
  utility: '',
  custom_utility: null,
  production_meter_location: '',
  foundation_type: '',
  beam_shape: '',
  custom_beam_shape: null,
  beam_spacing: ''
};

export const CAD_GENERATION_STATUS_META = {
  COLORS: {
    '': 'primary',
    'In Progress': 'info',
    Completed: 'success',
    Failed: 'error'
  },
  LABELS: {
    '': 'Not Completed',
    'In Progress': 'In Progress',
    Completed: 'Completed',
    Failed: 'Failed'
  }
};
